import { useEffect, useRef, useState } from 'react';

function App() {
  const [address, setAddress] = useState()
  const [loading, setLoading] = useState()
  const inputRef = useRef()
  const [data, setData] = useState()
  const handleCheck = () => {
    console.log(inputRef.current.value)
    setAddress(inputRef.current.value)
  }
  useEffect(() => {
    if (!address) {
      return
    }
    setLoading(true)
    // fetch(`https://galaxy.thammada.workers.dev`, {
    fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address
      }),
      redirect: 'follow'
    }).then(res => res.json()).then(d => {
      setData(d)
      setLoading(false)
    })
  }, [address])
  // const [boost] = Object.values(data?.galaxyGirl?.boost ?? {}) ?? [0]
  return (
    <div className="App" style={{ width: 480, margin: '2em auto' }}>
      <div>Project Galaxy released an official tool to check for $GAL Airdrop, visit <a href="https://galaxy.eco" target="_blank" rel="noreferrer">https://galaxy.eco</a> and click "GAL Airdrop"</div>
      <div style={{ display: 'none' }}>
        <input ref={inputRef} style={{ width: 320 }} placeholder="0x..." /><button onClick={handleCheck} disabled={loading}>{loading ? 'Loading...' : 'Check'}</button>
        {data && <>
        <h2>Total Airdrop: {data.shadowyCoder.airdrop + data.metaCowboy.airdrop + data.drSol.airdrop + data.galaxyGirl.airdrop} $GAL</h2>
        <div style={{ margin: '1.5em 0' }}>
          <h3>Shadowy Coder NFTs - Airdrop: {data.shadowyCoder.airdrop} $GAL</h3>
          {Object.keys(data.shadowyCoder.count).length > 0
            ? Object.entries(data.shadowyCoder.count).map(([name, count]) => <div key={name}>{name}: x{count}</div>)
            : 'No Shadowy Coder NFT'
          }
        </div>
        <div style={{ margin: '1.5em 0' }}>
          <h3>Metacowboy NFTs - Airdrop: {data.metaCowboy.airdrop} $GAL</h3>
          {Object.keys(data.metaCowboy.count).length > 0
            ? Object.entries(data.metaCowboy.count).map(([name, count]) => <div key={name}>{name}: x{count}</div>)
            : 'No Metacowboy NFT'
          }
        </div>
        <div style={{ margin: '1.5em 0' }}>
          <h3>Dr.SOL NFTs - Airdrop: {data.drSol.airdrop} $GAL</h3>
          {Object.keys(data.drSol.count).length > 0
            ? Object.entries(data.drSol.count).map(([name, count]) => <div key={name}>{name}: x{count}</div>)
            : 'No Dr.SOL NFT'
          }
        </div>
        <div style={{ margin: '1.5em 0' }}>
          <h3>Galaxy Girl NFT - Airdrop: {data.galaxyGirl.airdrop} $GAL</h3>
          {Object.keys(data.galaxyGirl.count).length > 0 
            ? Object.entries(data.galaxyGirl.count).map(([name, count]) => <div key={name}>{name}: x{count}</div>)
            : 'No Galaxy Girl NFT'
          }
          <br />
          {data.galaxyGirl.boost && <>
            Boost: {Object.entries(data.galaxyGirl.boost).map(([name, boost]) => <span key={name}>*{boost}: ({name})</span>)}
          </>}
        </div>
        </>}
      </div>
    </div>
  );
}

export default App;
